import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Footer from '@components/modules/Footer';
import Header from '@components/modules/Header';
import MinimalPage from '@components/containers/MinimalPage';
import FlexBox from '@components/containers/FlexBox';
import { metaInfo } from '@constants';

import useScrollState from '@utils/useScrollState';

const Page = ({
  children,
  noLinks,
}) => {
  const { isOnTop } = useScrollState();
  return (
    <MinimalPage>
      <Helmet>
        <title>{metaInfo.GENERIC.TITLE}</title>
        <meta name="description" content={metaInfo.GENERIC.DESCRIPTION} />
      </Helmet>
      <Header isOnTop={isOnTop} noLinks={noLinks} />
      <FlexBox
        flexDirection="column"
        alignItems="stretch"
        flexGrow="1"
        minHeight="70vh"
      >
        {children}
      </FlexBox>
      <Footer silentCookies />
    </MinimalPage>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  noLinks: PropTypes.bool,
};

Page.defaultProps = {
  noLinks: false,
};

export default Page;
