/* eslint-disable moank/no-import-styled-component */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { showModal } from '@redux/actions/ui';

import types from '@components/modals/types';

import FooterStyled, {
  FooterContainerStyled,
  CopyrightStyled,
} from '@components/modules/FooterStyled';
import Paragraph from '@components/elements/Paragraph';

import Anchor from '@components/elements/Anchor';

const currentYear = () => new Date().getFullYear();

const Footer = ({ silentCookies }) => {
  const dispatch = useDispatch();

  const openCookieSettings = useCallback((e) => {
    e.preventDefault();
    dispatch(showModal({
      type: types.COOKIES,
      options: { preventClosing: true },
    }));
  }, [dispatch]);

  return (
    <FooterStyled>
      <FooterContainerStyled>
        <CopyrightStyled>
          <Paragraph>
            Copyright ©{currentYear()} Moank. Alla rättigheter förbehålls.
            {silentCookies && <> Vi använder <Anchor underlined onClick={openCookieSettings}>kakor</Anchor> för att ge dig bästa möjliga kundupplevelse</>}
          </Paragraph>
        </CopyrightStyled>
      </FooterContainerStyled>
    </FooterStyled>
  );
};

Footer.propTypes = {
  silentCookies: PropTypes.bool,
};

Footer.defaultProps = {
  silentCookies: true,
};

export default Footer;
