import React from 'react';
import PropTypes from 'prop-types';
import FlexBox from '@components/containers/FlexBox';
import Loader from '@components/elements/Loader';

const Card = ({ loading, children }) => (
  <FlexBox
    flexDirection="column"
    backgroundColor="secondaryLightGray1"
    paddingRight="regular"
    paddingLeft="regular"
    paddingTop="medium"
    paddingBottom="medium"
    marginBottom="regular"
    fullWidth
  >
    {loading ? <Loader /> : children}
  </FlexBox>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

Card.defaultProps = {
  loading: false,
};

export default Card;
