import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';

import FlexBox from '@components/containers/FlexBox';
import TableRow from '@components/elements/table/TableRow';
import TableCell from '@components/elements/table/TableCell';

import Icon from '@components/elements/Icon';

import numberFormat from '@utils/numberFormat';
import style from '@constants/style';

import { downloadInvoice } from '@redux/actions/documents';

import DownloadInvoiceIcon from '@assets/img/icons/download-invoice.svg';

const Invoice = ({ loanId, invoice }) => {
  const dispatch = useDispatch();

  const isExpired = DateTime.now() > DateTime.fromISO(invoice.expiryDate);

  const handleDownload = useCallback(() => {
    dispatch(downloadInvoice(loanId, invoice._id));
  }, [dispatch, loanId, invoice._id]);

  return (
    <>
      <TableRow>
        <TableCell>
          <FlexBox>
            <FlexBox alignItems="center" marginRight="small" onClick={handleDownload} cursor="pointer">
              <Icon
                icon={invoice.pdfGeneratedAt ? DownloadInvoiceIcon : null}
                marginLeft="none"
                marginRight="none"
              />
            </FlexBox>
            <FlexBox alignItems="center" flexWrap="wrap" justifyContent="space-between" flexGrow="1">
              <FlexBox minWidth="6.5rem" marginRight="tiny"><strong style={{ fontSize: style.fontSize.small }}>{DateTime.fromISO(invoice.expiryDate).toFormat('dd MMM yyyy')}</strong></FlexBox>
              <FlexBox minWidth="5.5rem"><span style={{ fontSize: style.fontSize.small }}>{isExpired ? 'Utfört' : 'Kommande'}</span></FlexBox>
            </FlexBox>
          </FlexBox>
        </TableCell>
        <TableCell textAlign="right" fontWeight="bold">{numberFormat(invoice.amount, 2, { unit: 'kr' })}</TableCell>
      </TableRow>
    </>
  );
};

Invoice.propTypes = {
  invoice: PropTypes.shape({
    _id: PropTypes.string,
    expiryDate: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    pdfGeneratedAt: PropTypes.string,
  }).isRequired,
  loanId: PropTypes.string.isRequired,
};

export default Invoice;
