/* eslint-disable moank/no-import-styled-component */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import HeaderStyled, { HeaderItemsStyled } from '@components/modules/HeaderStyled';
import MoankLogo from '@components/elements/MoankLogo';

import Icon from '@components/elements/Icon';
import FlexBox from '@components/containers/FlexBox';
import LoginAnchor from '@components/elements/LoginAnchor';

import urls from '@constants/urls';
import getUrl from '@utils/getUrl';
import useIsLoggedIn from '@utils/useIsLoggedIn';

import hamburgerMenu from '@assets/img/icons/hamburger_menu.svg?inline';

import modalTypes from '@components/modals/types';
import { showModal } from '@redux/actions/ui';
import externals from '@constants/externals';

const Header = ({
  isOnTop,
  noLinks,
}) => {
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();

  const goToLanding = useCallback((e) => {
    e.preventDefault();
    if (isLoggedIn) navigate(getUrl(urls.HOME));
    else window.location = externals.WEBSITE;
  }, [isLoggedIn]);

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(showModal({ type: modalTypes.MENU }));
    }, [dispatch],
  );

  return (
    <HeaderStyled isOnTop={isOnTop}>
      <MoankLogo onClick={goToLanding} alt="Moank." />
      {!noLinks && (
        <HeaderItemsStyled>
          {!isLoggedIn && <LoginAnchor />}
          <FlexBox marginLeft="huge">
            <Icon onClick={onClick} icon={hamburgerMenu} aria-label="Meny" headerIcon />
          </FlexBox>
        </HeaderItemsStyled>
      )}
    </HeaderStyled>
  );
};

Header.propTypes = {
  isOnTop: PropTypes.bool,
  noLinks: PropTypes.bool,
};

Header.defaultProps = {
  noLinks: false,
  isOnTop: false,
};

export default Header;
