import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useGetLoans from '@utils/useGetLoans';
import { navigate } from 'gatsby';

import getUrl from '@utils/getUrl';
import urls from '@constants/urls';

const useGetLoan = (loanId, fallbackUrl) => {
  const dispatch = useDispatch();
  const { loading, loans } = useGetLoans();

  const loan = loans && loans[loanId];
  const error = !loan && !loading;
  useEffect(() => {
    if (error) {
      navigate(fallbackUrl || getUrl(urls.HOME));
    }
  }, [dispatch, error, fallbackUrl]);

  return { loan, loading };
};

export default useGetLoan;
