import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';

import Page from '@components/containers/Page';
import FlexBox from '@components/containers/FlexBox';

import Icon from '@components/elements/Icon';
import Headline from '@components/elements/Headline';
import Anchor from '@components/elements/Anchor';

import { logout } from '@redux/actions/auth';

import style from '@constants/style';
import moankTheme from '@constants/moankTheme';

import arrow from '@assets/img/icons/arrow-left.svg';

import getAccessToken, { CHECK_TIME } from '../../utils/getAccessToken';

const CONTENT_MARGIN = '1rem';
const TITLE_HEIGHT = '3.5rem';
const ARROW_WIDTH = '2.5rem';

export const ContentSection = styled('div')`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: calc(100% - (${CONTENT_MARGIN} * 2));
  ${({ fullWidth }) => (!fullWidth ? 'max-width: 34rem;' : '')}
  margin: 2rem ${CONTENT_MARGIN};

  ${style.media.tablet`
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  `}
`;

const LoggedPage = ({
  children,
  title,
  previousUrl,
  fullWidth,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!getAccessToken()) dispatch(logout());
    const interval = setInterval(() => {
      if (!getAccessToken()) dispatch(logout());
    }, CHECK_TIME * 1000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const theme = {
    primaryColour: moankTheme.primaryColour,
    secondaryColour: moankTheme.secondaryColour,
    buttonPrimaryColour: moankTheme.buttonPrimaryColour,
    buttonSecondaryColour: moankTheme.buttonSecondaryColour,
    sliderColour: moankTheme.sliderColour,
    sliderHandlerColour: moankTheme.sliderHandlerColour,
    headerBackground: moankTheme.primaryColour,
    pageBackground: style.colours.secondaryLightGray4,
    headerIconColour: style.colours.primaryLight,
  };

  return (
    <ThemeProvider theme={theme}>
      <Page
        headerShadow={false}
      >
        <ContentSection fullWidth={fullWidth}>
          <FlexBox
            marginBottom="regular"
            fullWidth
          >
            {previousUrl && (
              <Anchor to={previousUrl}>
                <FlexBox
                  justifyContent="center"
                  minHeight={TITLE_HEIGHT}
                  alignItems="center"
                  backgroundColor="secondaryDarkGray1"
                  padding="none"
                  minWidth={ARROW_WIDTH}
                >
                  <Icon icon={arrow} />
                </FlexBox>
              </Anchor>
            )}
            <FlexBox
              justifyContent="center"
              minHeight={TITLE_HEIGHT}
              alignItems="center"
              backgroundColor="secondaryLightGray1"
              paddingRight="bigger"
              paddingLeft={previousUrl ? 'none' : 'bigger'}
              fullWidth
            >
              <Headline
                as="h1"
                textAlign="center"
                fontSize="tiny"
                tabletBig
                paddingTop="small"
                paddingBottom="small"
                paddingLeft="regular"
                paddingRight="regular"
              >
                {title}
              </Headline>
            </FlexBox>
          </FlexBox>
          {children}
        </ContentSection>
      </Page>
    </ThemeProvider>
  );
};

LoggedPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  previousUrl: PropTypes.string,
  fullWidth: PropTypes.bool,
};

LoggedPage.defaultProps = {
  previousUrl: null,
  fullWidth: false,
};

export default LoggedPage;
