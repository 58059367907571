import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getLoans } from '@redux/actions/accounts';
import useRehydrated from '@utils/useRehydrated';

const useGetLoans = () => {
  const loading = useSelector((state) => state.accounts.loading);
  const loans = useSelector((state) => state.accounts.loans, shallowEqual);

  const [isFetchingLoans, setIsFetchingLoans] = useState(true);

  const rehydrated = useRehydrated();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!rehydrated) return;

    dispatch(getLoans());
    setIsFetchingLoans(false);
  }, [rehydrated, dispatch]);

  const isLoading = isFetchingLoans || loading;

  return { loading: isLoading, error: !isLoading && !loans, loans };
};

export default useGetLoans;
