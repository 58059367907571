import styled from 'styled-components';
import style from '@constants/style';

import { StyledAnchor } from '@components/elements/Anchor';
import { StyledParagraph } from '@components/elements/Paragraph';

import { StyledFlexBox } from '@components/containers/FlexBox';

const footerPadding = {
  mobile: style.padding.regular,
  laptop: style.padding.bigger,
};

const FOOTER_VERTICAL_PADDING = style.padding.bigger;

const FooterStyled = styled('footer')`
  background-color: ${style.colours.primaryDark};
  display: flex;
  justify-content: center;

  padding: ${FOOTER_VERTICAL_PADDING} ${footerPadding.mobile};

  ${StyledParagraph} {
    color: ${style.colours.secondaryLightGray1};
  }

  ${style.media.laptop`
    padding: ${FOOTER_VERTICAL_PADDING} ${footerPadding.laptop};
  `}
`;

export const CopyrightStyled = styled(StyledFlexBox)`
  justify-content: center;
  flex-grow: 1;

  ${StyledParagraph} {
    max-width: 50rem;
    text-align: center;
    font-size: ${style.fontSize.smaller};
  }
`;

export const FooterContainerStyled = styled(StyledFlexBox)`
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 30rem;
  margin-top: ${style.margin.bigger};

  ${CopyrightStyled} {order: 1; }

  ${style.media.laptop`
    max-width: 70rem;
  `}

  ${StyledAnchor} {
    color: ${style.colours.secondaryLightGray1};
  }
`;

export default FooterStyled;
