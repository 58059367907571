import cookies from 'js-cookie';
import { DateTime } from 'luxon';
import { getJWTPayload } from './jwt';

export const CHECK_TIME = 5;

const getAccessToken = () => {
  const token = cookies.get('token');
  if (!token) return undefined;

  const { exp } = getJWTPayload(token);
  const tokenExpiryDate = DateTime.fromMillis(exp * 1000);
  const now = DateTime.now().plus({ seconds: CHECK_TIME });
  if (tokenExpiryDate > now) return token;
  return undefined;
};

export default getAccessToken;
