import React from 'react';
import PropTypes from 'prop-types';

import LoggedPage from '@components/containers/LoggedPage';
import Card from '@components/containers/Card';
import Invoice from '@components/containers/Invoice';
import FlexBox from '@components/containers/FlexBox';

import Table from '@components/elements/table/Table';
import Paragraph from '@components/elements/Paragraph';

const InvoiceList = ({
  loading,
  previousUrl,
  accountId,
  invoices,
}) => (
  <LoggedPage
    title="Mina betalningar"
    previousUrl={previousUrl}
  >
    <Card loading={loading}>
      <FlexBox overflow="auto" flexDirection="column">
        {invoices.length === 0
          ? <Paragraph textAlign="center" marginBottom="none">Du har inga fakturor än</Paragraph> : null}
        <Table borderAfter>
          <tbody>
            {invoices.map((invoice) => invoice._id && <Invoice key={invoice._id} invoice={invoice} loanId={accountId} />)}
          </tbody>
        </Table>
      </FlexBox>
    </Card>
  </LoggedPage>
);

InvoiceList.propTypes = {
  loading: PropTypes.bool.isRequired,
  previousUrl: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      expiryDate: PropTypes.string,
      createdAt: PropTypes.string,
      amount: PropTypes.number,
      pdfGeneratedAt: PropTypes.string,
    }),
  ).isRequired,
};

export default InvoiceList;
