import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import getUrl from '@utils/getUrl';
import urls from '@constants/urls';
import useGetLoan from '@utils/useGetLoan';

import InvoiceList from '@components/containers/InvoiceList';

const LoanPaymentPlan = ({ loanId }) => {
  const { loading, loan } = useGetLoan(loanId, getUrl(urls.HOME));

  const previousUrl = getUrl(urls.HOME.MY_LOANS.DETAILS, { loanId });

  const isActive = useSelector(
    ({ settings }) => (loan && loan.status) === settings.constants.accountStatuses.ACTIVE,
  );

  const invoices = loan ? loan.invoices : [];

  return (
    <InvoiceList
      loading={loading}
      previousUrl={previousUrl}
      accountId={loanId}
      isActive={isActive}
      invoices={invoices}
    />
  );
};

LoanPaymentPlan.propTypes = {
  loanId: PropTypes.string.isRequired,
};

export default LoanPaymentPlan;
